import { Button, Col, InputNumber, Modal, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { refundDeposit } from "../../../redux/actions/orderActions";
import { filteredDistance } from "../../../utils/filteredDistance";
import { filteredPrice } from "../../../utils/filteredPrice";

const OrdersTemplate = () => {
  const { token } = useSelector((state) => state.adminReducer);
  const { orders } = useSelector((state) => state.adminReducer);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [amount, setAmount] = useState(0);

  const onSelectOrder = (order) => {
    setSelectedOrder(order);
    setAmount(order.depositPrice);
  };
  const onClose = () => {
    setSelectedOrder(null);
    setAmount(0);
  };

  const onRefundDeposit = () => {
    const body = {
      amount,
      paymentId: selectedOrder.payment.id,
    };
    refundDeposit(token, selectedOrder._id, body);
  };

  return (
    <div>
      <Modal open={selectedOrder} footer={null} onCancel={onClose}>
        <div>Remboursement de la caution</div>
        <InputNumber
          value={amount}
          onChange={setAmount}
          max={selectedOrder?.depositPrice}
        />
        <div>
          <Button type="primary" onClick={onRefundDeposit}>
            Rembourser
          </Button>
        </div>
      </Modal>
      {orders.map((order) => (
        <div key={order._id} style={{ marginBottom: 20 }}>
          <Row style={{ border: "1px solid lightgray" }}>
            <Col span={5}>
              <div
                style={{
                  borderRight: "1px solid lightgray",
                  padding: 10,
                  fontSize: 11,
                }}
              >
                Matériels
              </div>
            </Col>
            <Col span={3}>
              <div
                style={{
                  borderRight: "1px solid lightgray",
                  padding: 10,
                  fontSize: 11,
                }}
              >
                Dates
              </div>
            </Col>
            <Col span={2}>
              <div
                style={{
                  borderRight: "1px solid lightgray",
                  padding: 10,
                  fontSize: 11,
                }}
              >
                Caution
              </div>
            </Col>
            <Col span={3}>
              <div
                style={{
                  borderRight: "1px solid lightgray",
                  padding: 10,
                  fontSize: 11,
                }}
              >
                Contact
              </div>
            </Col>
            <Col span={5}>
              <div
                style={{
                  borderRight: "1px solid lightgray",
                  padding: 10,
                  fontSize: 11,
                }}
              >
                Adresse
              </div>
            </Col>
            <Col span={2}>
              <div
                style={{
                  borderRight: "1px solid lightgray",
                  padding: 10,
                  fontSize: 11,
                }}
              >
                Distance
              </div>
            </Col>
            <Col span={2}>
              <div
                style={{
                  borderRight: "1px solid lightgray",
                  padding: 10,
                  fontSize: 11,
                }}
              >
                Livraison
              </div>
            </Col>
            <Col span={2}>
              <div style={{ padding: 10, fontSize: 11 }}>Location</div>
            </Col>
          </Row>
          {order.cart.map((cart) => {
            let totalPrice = 0;
            let price = 0;
            if (cart.selectedDate.length <= 5) {
              price = cart.article.price.daily;
            } else if (cart.selectedDate.length <= 20) {
              price = cart.article.price.weekly;
            } else {
              price = cart.article.price.monthly;
            }
            totalPrice = price * cart.selectedDate.length;
            return (
              <Row
                key={cart._id}
                style={{
                  borderBottom: "1px solid lightgray",
                  borderLeft: "1px solid lightgray",
                  borderRight: "1px solid lightgray",
                }}
              >
                <Col span={5} style={{ height: "auto", overflow: "hidden" }}>
                  <div
                    style={{
                      borderRight: "1px solid lightgray",
                      padding: 10,
                      fontSize: 11,
                      height: "100%",
                    }}
                  >
                    {cart.article.name}
                  </div>
                </Col>
                <Col span={3} style={{ height: "auto", overflow: "hidden" }}>
                  <div
                    style={{
                      borderRight: "1px solid lightgray",
                      padding: 10,
                      fontSize: 11,
                      height: "100%",
                    }}
                  >
                    {cart.selectedDate.map((item, j) => {
                      return (
                        <div
                          className="flex"
                          key={j}
                          style={{ marginBottom: 5 }}
                        >
                          <div className="w-full">
                            <div>
                              {new Date(item.date).toLocaleDateString("fr")}
                              {order.user.type === "private" ? (
                                <span className="ml-2">
                                  (
                                  {cart.selectedDate.length <= 5
                                    ? `${filteredPrice(
                                        cart.article.price.daily * 1.2
                                      )} TTC`
                                    : cart.selectedDate.length <= 20
                                    ? `${filteredPrice(
                                        cart.article.price.weekly * 1.2
                                      )} TTC`
                                    : `${filteredPrice(
                                        cart.article.price.monthly * 1.2
                                      )} TTC`}
                                  )
                                </span>
                              ) : (
                                <span className="ml-2">
                                  (
                                  {cart.length <= 5
                                    ? filteredPrice(cart.article.price.daily)
                                    : cart.length <= 20
                                    ? filteredPrice(cart.article.price.weekly)
                                    : filteredPrice(cart.article.price.monthly)}
                                  )
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Col>
                <Col span={2} style={{ height: "auto", overflow: "hidden" }}>
                  <div
                    style={{
                      borderRight: "1px solid lightgray",
                      padding: 10,
                      fontSize: 11,
                      height: "100%",
                    }}
                  >
                    {filteredPrice(cart.article.price.deposit)}
                  </div>
                </Col>
                <Col span={3} style={{ height: "auto", overflow: "hidden" }}>
                  <div
                    style={{
                      borderRight: "1px solid lightgray",
                      padding: 10,
                      fontSize: 11,
                      height: "100%",
                    }}
                  >
                    <div style={{ marginBottom: 5 }}>
                      <span style={{ textTransform: "capitalize" }}>
                        {cart.contact.civility}
                      </span>{" "}
                      {cart.contact.firstname}{" "}
                      <span style={{ textTransform: "uppercase" }}>
                        {cart.contact.lastname}
                      </span>
                    </div>
                    <div style={{ marginBottom: 5 }}>{cart.contact.email}</div>
                    <div>{cart.contact.phone}</div>
                  </div>
                </Col>
                <Col span={5} style={{ height: "auto", overflow: "hidden" }}>
                  <div
                    style={{
                      borderRight: "1px solid lightgray",
                      padding: 10,
                      fontSize: 11,
                      height: "100%",
                    }}
                  >
                    {cart.isDelivery
                      ? cart.selectedLocation.address
                      : "Retrait sur place"}
                  </div>
                </Col>
                <Col span={2} style={{ height: "auto", overflow: "hidden" }}>
                  <div
                    style={{
                      borderRight: "1px solid lightgray",
                      padding: 10,
                      fontSize: 11,
                      height: "100%",
                    }}
                  >
                    {cart?.selectedLocation
                      ? filteredDistance(cart.selectedLocation.distance)
                      : filteredDistance(0)}
                  </div>
                </Col>
                <Col span={2} style={{ height: "auto", overflow: "hidden" }}>
                  <div
                    style={{
                      borderRight: "1px solid lightgray",
                      padding: 10,
                      fontSize: 11,
                      height: "100%",
                    }}
                  >
                    {order.user.type === "private" ? (
                      <span>
                        {cart.isDelivery
                          ? `${filteredPrice(
                              (cart.selectedLocation?.distance / 1000) *
                                cart.article.price.delivery *
                                1.2
                            )} TTC`
                          : filteredPrice(0)}
                      </span>
                    ) : (
                      <span>
                        {cart.isDelivery
                          ? filteredPrice(
                              (cart.selectedLocation?.distance / 1000) *
                                cart.article.price.delivery
                            )
                          : filteredPrice(0)}{" "}
                        HT
                      </span>
                    )}
                  </div>
                </Col>
                <Col span={2} style={{ height: "auto", overflow: "hidden" }}>
                  <div style={{ padding: 10, fontSize: 11, height: "100%" }}>
                    {order.user.type === "private"
                      ? filteredPrice(
                          totalPrice * 1.2 + cart.article.price.deposit
                        )
                      : filteredPrice(totalPrice + cart.article.price.deposit)}
                  </div>
                </Col>
              </Row>
            );
          })}
          <Row
            style={{
              borderBottom: "1px solid lightgray",
              borderRight: "1px solid lightgray",
              borderLeft: "1px solid lightgray",
            }}
          >
            {console.log(order)}
            <Col span={8}>
              <div style={{ padding: 10 }}>
                <div
                  style={{ fontWeight: "bold", marginBottom: 5, fontSize: 11 }}
                >
                  Client
                </div>
                <div
                  style={{ fontWeight: "bold", marginBottom: 5, fontSize: 11 }}
                >
                  <span style={{ textTransform: "capitalize" }}>
                    {order.user.civility}
                  </span>{" "}
                  {order.user.firstname}{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {order.user.lastname}
                  </span>
                </div>
                <div
                  style={{ fontWeight: "bold", marginBottom: 5, fontSize: 11 }}
                >
                  Type :{" "}
                  {order.user.type === "private"
                    ? "Particulier"
                    : "Professionnel"}
                </div>
                <div
                  style={{ fontWeight: "bold", marginBottom: 5, fontSize: 11 }}
                >
                  Email : {order.user.email}
                </div>

                <div style={{ fontWeight: "bold", fontSize: 11 }}>
                  Téléphone : {order.user.phone}
                </div>
              </div>
            </Col>
            <Col span={16}>
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      border: "1px solid lightgray",
                      padding: 10,
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        marginBottom: 5,
                        fontSize: 11,
                      }}
                    >
                      Location de {filteredPrice(order.rentalPrice)}
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        marginBottom: 5,
                        fontSize: 11,
                      }}
                    >
                      Livraison de {filteredPrice(order.deliveryPrice)}
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        marginBottom: 5,
                        fontSize: 11,
                      }}
                    >
                      Assurance : {filteredPrice(order.insurancePrice)}
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid lightgray",
                      padding: 10,
                      margin: "0 10px",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        marginBottom: 5,
                        fontSize: 11,
                      }}
                    >
                      Caution : {filteredPrice(order.depositPrice)}
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 11,
                      }}
                    >
                      {order.depositPay
                        ? "Caution à payer sur place"
                        : "Caution déjà payée"}
                    </div>
                    {!order.depositPay && (
                      <>
                        {order.refund.status ? (
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: 11,
                              marginTop: 5,
                            }}
                          >
                            {filteredPrice(order.refund.amount)} remboursé sur
                            la caution
                          </div>
                        ) : (
                          <div style={{ marginTop: 10 }}>
                            <Button
                              size="small"
                              type="primary"
                              onClick={() => onSelectOrder(order)}
                            >
                              Rembourser la caution
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      border: "1px solid lightgray",
                      padding: 10,
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        marginBottom: 5,
                        fontSize: 11,
                      }}
                    >
                      Montant payé : {filteredPrice(order.finalPrice)}
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        marginBottom: 5,
                        fontSize: 11,
                      }}
                    >
                      Reste à payer : {filteredPrice(order.restToPay)}
                    </div>
                    <div style={{ fontWeight: "bold", fontSize: 11 }}>
                      Prix total :{" "}
                      {filteredPrice(order.restToPay + order.finalPrice)}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
};

export default OrdersTemplate;
