import axios from "axios";
import { REFUND_PAYPAL_URL } from "../api/userApi";

export const refundDeposit = (token, id, body) => {
  axios
    .post(`${REFUND_PAYPAL_URL}/${id}`, body, {
      headers: { "auth-token": token },
    })
    .then((res) => console.log(res.data))
    .catch((err) => console.log(err));
};
