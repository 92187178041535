import { Button, Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import "./styles.css";

function CategoriesList(props) {
  const { editModal } = props;
  const { categories } = useSelector((state) => state.categoryReducer);

  return (
    <div>
      <Row className="home_head_table">
        <Col xl={3} className="h-auto">
          <div className="home_head_table_cell">Nom</div>
        </Col>
        <Col xl={2} className="h-auto">
          <div className="home_head_table_cell">Action</div>
        </Col>
      </Row>
      {categories.map((category) => (
        <Row key={category._id} className="home_body_table">
          <Col xs={22} xl={3} className="h-auto">
            <div className="home_head_table_cell home_body_table_text_right home_body_table_title">
              {category.name}
            </div>
          </Col>
          <Col xs={24} xl={2} className="h-auto">
            <div className="p5 h-full home_head_table_cell">
              <Button
                className="w-full"
                type="primary"
                onClick={() => editModal(category._id)}
              >
                Modifier
              </Button>
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
}

export default CategoriesList;
