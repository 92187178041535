import { Button, Form, Input, Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCategory,
  getCategories,
  updateCategory,
} from "../../../redux/actions/categoryActions";
import "./styles.css";

function CategoryModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (toggleModal.category) {
      getCategories(token, toggleModal.category)
        .then((res) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            name: res.data.name,
          };
          form.setFieldsValue(fields);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModal, form]);

  const onAddCategory = (value) => {
    const body = {
      ...value,
      price: {
        daily: value.daily,
        weekly: value.weekly,
        delivery: value.delivery,
        deposit: value.deposit,
      },
    };
    if (toggleModal.category) {
      updateCategory(token, toggleModal.category, body, closeModal, dispatch);
    } else {
      addCategory(token, body, closeModal, dispatch);
    }
  };

  return (
    <Modal
      title={
        toggleModal.category ? "Modifier la categorie" : "Ajouter une categorie"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
    >
      <Form
        layout="vertical"
        onFinish={onAddCategory}
        form={form}
        initialValues={{
          name: "",
          order: "",
          active: true,
          isReservable: true,
        }}
      >
        <Form.Item
          label="Nom de la cartégorie"
          name="name"
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom du matériel!",
            },
          ]}
        >
          <Input placeholder="Pelleteuse" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CategoryModal;
