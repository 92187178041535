import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

const Header = () => {
  return (
    <header>
      <div className="headerContainer">
        <div className="headerLogo">Logo</div>
        <nav className="headerNav">
          <Link className="headerNavLink" to="/">
            Home
          </Link>
          <Link className="headerNavLink" to="/orders">
            Commandes
          </Link>
          <Link className="headerNavLink" to="/categories">
            Catégories
          </Link>
          <Link className="headerNavLink" to="/articles">
            Articles
          </Link>
          <Link className="headerNavLink" to="/utilisateurs">
            Utilisateurs
          </Link>
          <Link className="headerNavLink" to="/profile">
            Profil
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
