import React, { useState } from "react";
import { CategoryModal } from "../../mollecules";
import { CategoriesList, OpelModal } from "../../organisms";

function CategoriesTemplate() {
  const [toggleCategoryModal, setToggleCategoryModal] = useState({
    active: false,
    category: null,
  });

  const openCategoryModal = () => {
    setToggleCategoryModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const closeCategoryModal = () => {
    setToggleCategoryModal((prevState) => ({
      ...prevState,
      active: false,
      category: null,
    }));
  };
  const editCategoryModal = (id) => {
    setToggleCategoryModal((prevState) => ({
      ...prevState,
      active: true,
      category: id,
    }));
  };
  return (
    <div>
      {toggleCategoryModal.active && (
        <CategoryModal
          closeModal={closeCategoryModal}
          toggleModal={toggleCategoryModal}
        />
      )}
      <OpelModal title="Ajouter un category" openModal={openCategoryModal} />
      <CategoriesList editModal={editCategoryModal} />
    </div>
  );
}

export default CategoriesTemplate;
