import axios from "axios";
import { toast } from "react-toastify";
import { CATEGORIES_URL, GET_CATEGORIES_URL } from "../api/userApi";
import {
  addCategoryFailed,
  addCategoryRequest,
  addCategorySuccess,
  getCategoriesFailed,
  getCategoriesRequest,
  getCategoriesSuccess,
} from "../reducers/categoryReducer";

export const getCategories = (token, dispatch) => {
  dispatch(getCategoriesRequest());
  axios
    .post(GET_CATEGORIES_URL, null, { headers: { "auth-token": token } })
    .then((res) => {
      dispatch(getCategoriesSuccess(res.data));
      toast(res.data.message, { type: "success" });
    })
    .catch((err) => {
      dispatch(getCategoriesFailed());
      toast(err.response.data.message, { type: "error" });
    });
};

export const addCategory = (token, body, closeModal, dispatch) => {
  dispatch(addCategoryRequest());
  axios
    .post(CATEGORIES_URL, body, { headers: { "auth-token": token } })
    .then((res) => {
      dispatch(addCategorySuccess());
      toast(res.data.message, { type: "success" });
      getCategories(token, dispatch);
      closeModal();
    })
    .catch((err) => {
      dispatch(addCategoryFailed());
      toast(err.response.data.message, { type: "error" });
    });
};

export const updateCategory = (token, id, body, closeModal, dispatch) => {
  dispatch(addCategoryRequest());
  axios
    .put(`${CATEGORIES_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch(addCategorySuccess());
      toast(res.data.message, { type: "success" });
      closeModal();
      getCategories(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch(addCategoryFailed());
    });
};
